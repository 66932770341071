import { isPlatform } from '@ionic/angular/standalone';
import config from '../../capacitor.config';

export const { appId } = config;
export const isHybrid = isPlatform('hybrid');

export const env = {
  // Auth0 configuration
  production: false,
  audience: 'https://onech.ec',
  domain: 'onecheck.us.auth0.com',
  clientId: 'Qd7BYIiyiu3s3J2aJWVC6cfaNAVHmgNs',
  auth0Domain: 'onecheck.us.auth0.com',
  appId,
  isHybrid,
  protocol: '',
  auth0Callback: '',
  apiUrl: '',

  // GetStream Values
  stream_api_key: 'm76qmenqpg3q',
};

export const addDependentVars = (env: any) => {
  env.protocol = env.isHybrid ? `${env.appId}://` : `${env.websiteDomain}/`;
  env.auth0Callback =
    env.isHybrid ? `${env.appId}://${env.auth0Domain}/capacitor/${env.appId}/callback` : env.websiteDomain;
  env.apiUrl = `${env.backendUrl}/api`;
};
